const lanBois = [{
    nickName: 'Bossie',
    photoFileName: 'edwin.jpeg',
    audioFileName: 'edwin-erika-techno.mp3'
}, {
    nickName: 'Mike',
    photoFileName: 'mike.jpeg',
    audioFileName: 'mike.mp3'
}, {
    nickName: 'Björn',
    photoFileName: 'bjorn.png',
    audioFileName: 'bjorn.mp3'
}, {
    nickName: 'RoccoW',
    photoFileName: 'rocco.jpeg',
    audioFileName: 'rocco.mp3'
}, {
    nickName: 'Fab',
    photoFileName: 'fab.jpeg',
    audioFileName: 'hampton-the-hampster-the-official-hamster-dance-song.mp3'
}, {
    nickName: 'HappyMartin',
    photoFileName: 'martin.jpeg',
    audioFileName: 'martin.mp3'
}, {
    nickName: 'The Godfather',
    photoFileName: 'bram.jpeg',
    audioFileName: 'astrix-deep-jungle-walk.mp3'
}, {
    photoFileName: 'berdien.jpeg',
    audioFileName: 'vreet-spirit.webm',
    nickName: 'Birdy'
}, {
    photoFileName: 'gosse.jpeg',
    audioFileName: 'gosse-theme.mp3',
    nickName: 'Goose'
}, {
    photoFileName: 'geertex.jpg',
    audioFileName: 'geertex.mp3',
    nickName: 'Geertex'
}, {
    photoFileName: 'dupol.jpeg',
    audioFileName: 'dupol-2.mp3',
    nickName: 'Dupol'
}, {
    photoFileName: 'martijn.jpeg',
    audioFileName: 'martijn.mp3',
    nickName: 'Martijn V1'
}, {
    photoFileName: 'gertjan.jpeg',
    audioFileName: 'rose-online-city-of-zant.webm',
    nickName: 'G-Jay'
}, {
    photoFileName: 'bing.jpeg',
    audioFileName: 'alweer-een-winnaar.mp3',
    nickName: 'Binky'
}, {
    photoFileName: 'jonathan.jpeg',
    audioFileName: 'boom-headshot.mp3',
    nickName: 'Snorbie'
}, {
    photoFileName: 'pa-2.jpeg',
    audioFileName: 'rawhide.webm',
    nickName: 'LANLOL sponsor'
}, {
    photoFileName: 'ma.jpeg',
    audioFileName: 'vreet-spirit.webm',
    nickName: 'LANLOL sponsor'
}, {
    photoFileName: 'martijn-2.jpeg',
    audioFileName: 'martijn-2.webm',
    nickName: 'Martijn 2.0'
}, {
    photoFileName: 'eward.jpeg',
    audioFileName: 'eward.mp3',
    nickName: 'Eward'
}, {
    photoFileName: 'gino.jpeg',
    audioFileName: 'tavern-world-of-warcraft-gino.mp3',
    nickName: 'Elendas'
}, {
    photoFileName: 'ronald.jpeg',
    audioFileName: 'vitas.mp3',
    nickName: 'Ronald'
}, {
    photoFileName: 'joowna.jpg',
    audioFileName: 'lanboi-joowna.mp3',
    nickName: 'Joowna'
}, {
    photoFileName: 'marco.jpg',
    audioFileName: 'rammstein-dicke-titten-marco.webm',
    nickName: 'Marco'
}, {
    photoFileName: 'bart.jpg',
    audioFileName: 'xbox-liemonade-john-cena.mp3',
    nickName: 'Zebbel AKA Lange AKA Gal Bart'
}];

export default lanBois;
